module.exports = {
    '景点': '관광지',
    '美食': '음식',
    '住宿': '숙박',
    '文化': '문화',
    '特产': '特产',
    '地图': '지도',
    '非遗': '비유',
    '商城': '상점',
    '我': '나의',
    '路线': '노선',
    '3D趣赏': '3D 재미',
    'AR导览': 'AR 가이드',
    'VR全景': 'VR 파노라마',
    '去这里': '여기로 가면',
    '留言': '메모 ',
    '留言墙': '메모벽 ',
    '请输入留言': '메모 입력',
    '非遗文化': '비문화',
    '趣赏非遗': '남김없이 감상하다',
    '非遗项目': '非遗项目།',
    '曲艺': '',
    '查看': '',
    '全部级别': '',
    '世界级': '',
    '国家级': '',
    '省级': '',
    '市级': '',
    '县级': '',
    '传承人': '',
    '阅': '',
    '云游青海': '',
    '游客模式': '',
    '微信登录': '',
    '分享': '分享',
    '微信': '',
    '微博': '',
    '朋友圈': '',
    'QQ': '',
    '男': '男',
    '女': '女',
    '保存': '保存',
    'AR趣玩': 'AR 놀이',
    '线上展馆': '온라인 전시관',
    '游戏互动': '게임 상호 작용',
    '云赏非遗': '남김없이 칭찬하다',
    '各派戏曲': '각 파의 희곡',
    '传统音乐': '전통 음악',
    '传统美术': '전통 미술',
    '民族舞蹈': '민족무용',
    '民间文学': '민간문학',
    '传统医学': '전통의학',
    '传统技艺': '전통 기예',
    '民俗文化': '민속 문화',
    '各类乐器': '각종 악기',
    '民族服饰': '민족 복식',
    '个人信息': '개인 정보',
    '我的足迹': '나의 발자국',
    '我的收藏': '나의 소장품',
    '昵称：': '닉네임',
    '请输入昵称': '닉네임을 입력하세요',
    '性别：': '성별',
    '保密：': '비밀에 부치다',
    '电话：': '전화',
    '请输入手机号': '핸드폰 번호를 입력하세요',
}