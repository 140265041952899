// 导入axios 
import axios from 'axios'
import Vue from "vue"
//  导入工具函数

// // 设置数据接口基础域名
// axios.defaults.baseURL = 'http://192.168.31.232:8088';    //本地
// axios.defaults.baseURL = 'http://114.115.177.231:9090'; //线上的接口
axios.defaults.baseURL = 'http://mapadmin.hanchaomr.com' //线上的接口


// 添加请求拦截器
axios.interceptors.request.use(function (request) {

    // 读取登录信息
    const loginInfo = JSON.parse(localStorage.getItem('adminInfo') || '{}')
    // 动态添加自定义请求头
    request.headers.authorization = loginInfo.token
    return request
})


// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 登录超时
    if (response.data.code === 300) {
        // 登录超时提示
        console.log(response.data.msg)
        // 自动跳转到登录页面
        location.href = `/login`
    }
    return response.data
})


// 在组件中通过this.$axios访问axios, 如何实现?
// 将axios挂载到vue的原型对象上, 方便在组件中直接使用
Vue.prototype.$axios = axios
// 导出axios
export default axios