import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'layOut',
    redirect: 'Home',
    component: () => import('../views/layOut.vue'),
    children: [
      // 地图
      {
        path: 'Home',
        name: 'Home',
        component: () => import('../views/Home.vue')
      },
      // 非遗
      {
        path: '/feiyi',
        name: 'Feiyi',
        component: () => import('../views/feiyi.vue'),
      },
      {
        path: "/project",
        name: "project",
        component: () => import('../views/feiyiSon/project.vue')
      },
      {
        path: "/details",
        name: "details",
        component: () => import('../views/feiyiSon/details.vue')
      },
      {
        path: "/feiyiItem",
        name: "feiyiItem",
        component: () => import('../views/feiyiSon/feiyiItem.vue')
      },
      // 商城
      {
        path: '/store',
        name: 'Store',
        component: () => import('../views/store.vue')
      },
      // 我的
      {
        path: '/mine',
        name: 'Mtore',
        component: () => import('../views/mine.vue')
      },
    ]
  },
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import('../views/iframe.vue')
  },
  //个人信息
  {
    path: '/person',
    name: 'person',
    component: () => import('../views/mineSon/person.vue')
  },
  // 我的足迹
  {
    path: '/footprint',
    name: 'Footprint',
    component: () => import('../views/mineSon/footprint.vue')
  },
  //我的收藏
  {
    path: '/collect',
    name: 'collect',
    component: () => import('../views/mineSon/collect.vue')
  },
  // 
  {
    path: '/conTent',
    name: 'ConTent',
    component: () => import('../views/home/conTent.vue')
  },
  // 路线
  {
    path: '/path',
    name: 'Path',
    component: () => import('../views/home/path.vue')
  },
  // 登录页
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  },
  // 留言
  {
    path: "/leaveWord",
    name: 'LeaveWord',
    component: () => import('../views/home/leaveWord.vue')
  },
  //搜索
  {
    path: "/search",
    name: "Search",
    component: () => import('../views/home/search.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  routes,
  // scrollBehavior: () => {
  //   history.pushState(null, null, document.URL)
  // }
})
// 
// router.beforeEach((to, from, next) => {
//   if (store.state.loginStatus == 0) {
//   //微信未授权登录跳转到授权登录页面
//   let url = window.location.href;
//   //解决重复登录url添加重复的code与state问题
//   let parseUrl = qs.parse(url.split('?')[1]);
//   let loginUrl;
//   if (parseUrl.code && parseUrl.state) {
//   delete parseUrl.code;
//   delete parseUrl.state;
//   loginUrl = `${url.split('?')[0]}?${qs.stringify(parseUrl)}`;
//   } else {
//   loginUrl = url;
//   }
//   wechatAuth.redirect_uri = loginUrl;
//   store.dispatch('setLoginStatus', 1);
//   window.location.href = wechatAuth.authUrl
//   } else if (store.state.loginStatus == 1) {
//   try {
//   wechatAuth.returnFromWechat(to.fullPath);
//   } catch (err) {
//   store.dispatch('setLoginStatus', 0)
//   next()
//   }
//   store.dispatch('loginWechatAuth', wechatAuth.code).then((res) => {
//   if (res.status == 1) {
//   store.dispatch('setLoginStatus', 2)
//   } else {
//   store.dispatch('setLoginStatus', 0)
//   }
//   next()
//   }).catch((err) => {
//   next()
//   })
//   }else {
//   next()
//   }
//  });
export default router
