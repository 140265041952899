import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n);
// Vant built-in lang
import { Locale } from 'vant';
import zhCN from "vant/es/locale/lang/zh-CN";

const messages = {
    enUS: require('./English'),//英文
    zhCN: {
        ...zhCN,
    },//汉语
    ti: require('./Tibetan'),//藏语
    jaJP: require('./Japanese'),//日语
    Korean: require('./Korean'),//韩文
};
const i18n = new VueI18n({
    locale: "zhCN", // 设置默认语言
    messages: messages, // 设置资源文件对象
});
function vantLocales(item) {
    i18n.locale = item.abbreviation
    Locale.use(i18n.locale, item.abbreviation)
}

export { i18n, vantLocales }