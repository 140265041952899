module.exports = {
    '景点': 'མཛེས་ལྗོངས།',
    '美食': 'ཟས་མཆོག།',
    '住宿': 'ཞག་སྡོད།',
    '文化': 'རིག་གནས།',
    '特产': 'ཁྱད་ཐོན་དངོས་རྫས།',
    '地图': 'ས་ཁྲ།',
    '非遗': 'མངོན་མིན་རིག་གནས་ཤུལ་བཞག།',
    '商城': 'ཚོང་ར།',
    '我': 'ངའི།',
    '路线': 'ལམ་ཐིག།',
    '3D趣赏': '3Dསྤྲོ་བས་བྱ་དགའ་སྤྲོད་པ་།',
    'AR导览': 'ARལྟ་སྟོན།',
    'VR全景': 'VRལྗོངས་ཡོངས་རྫོགས',
    '去这里': 'འདིར་འགྲོ་བ།',
    '留言': 'དྲན་འཆར་འགོད་པ།',
    '留言墙': 'དྲན་འཆར་འགོད་པ།',
    '请输入留言': 'དྲན་འཆར་འགོད་པ།',
    '非遗文化': 'མངོན་མིན་རིག་གནས་ཤུལ་བཞག་',
    '趣赏非遗': 'དགའ་མོས་ངང་མངོན་མིན་རིག་གནས་ཤུལ་བཞག་ལ་བྱ་དགའ་སྤྲོད་པ།',
    '非遗项目': 'མངོན་མིན་རིག་གནས་ཤུལ་བཞག་གི་རྣམ་གྲངས།',
    '曲艺': '',
    '查看': '',
    '全部级别': '',
    '世界级': '',
    '国家级': '',
    '省级': '',
    '市级': '',
    '县级': '',
    '传承人': '',
    '阅': '',
    '云游青海': '',
    '游客模式': '',
    '微信登录': '',
    '分享': '分享',
    '微信': '',
    '微博': '',
    '朋友圈': '',
    'QQ': '',
    '男': '男',
    '女': '女',
    '保存': '保存',
    'AR趣玩': 'ARརོལ་རྩེད',
    '线上展馆': 'དྲ་ཐོག་འགྲེམས་ཁང་',
    '游戏互动': 'རོལ་རྩེད་མཉམ་འགུལ།',
    '云赏非遗': 'སྤྲིན་རྩིས་མངོན་མིན་རིག་གནས་ཤུལ་བཞག་ལ་བྱ་དགའ་སྤྲོད་པ་བཅས',
    '各派戏曲': 'ཟློས་གར་དང་།',
    '传统音乐': 'སྲོལ་རྒྱུན་རོལ་མོ།',
    '传统美术': 'སྲོལ་རྒྱུན་རི་མོ།',
    '民族舞蹈': 'མི་རིགས་ཞབས་བྲོ།',
    '民间文学': 'དམངས་ཁྲོད་རྩོམ་རིག',
    '传统医学': 'སྲོལ་རྒྱུན་གསོ་རིག',
    '传统技艺': 'སྲོལ་རྒྱུན་གྱི་ལག་རྩལ་དང་།',
    '民俗文化': 'དམངས་སྲོལ་རིག་གནས།',
    '各类乐器': 'རོལ་ཆས་སྣ་ཚོགས།',
    '民族服饰': 'མི་རིགས་ཀྱི་གྱོན་གོས།',
    '个人信息': 'མི་སྒེར་གྱི་ཆ་འཕྲིན་',
    '我的足迹': 'དང་།ངའི་རྗེས་ཤུལ།',
    '我的收藏': 'བདག་གི་ཉར་ཚགས།',
    '昵称：': 'གཅེས་མིང་།',
    '请输入昵称': 'མིང་ནང་འཇུག་རོགས་།',
    '性别：': 'ཕོ་མོའི་དབྱེ་བ',
    '保密：': 'གསང་བ།',
    '电话：': 'ཁ་པར།',
    '请输入手机号': '་ཨང་གྲངས་འབྲི་རོགས།',
}