<template>
  <div id="app">
    <router-view v-if="isReload" />
    <!-- 底部导航栏 -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      isReload: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  components: {},
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    },
  },
};
</script>
<style lang="less">
@import url("./assets/css/reset.css");
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // -webkit-overflow-scrolling: touch; /*兼容iOS 当手指从触摸屏上移开，会保持一段时间的滚动 */
  color: #2c3e50;
}
</style>
