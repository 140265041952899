import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入axios
import axios from './tool/axios'
// window.prototype.Base64 = Base64
Vue.prototype.$axios = axios
// 把数据接口服务器基础域名挂载到vue的原型对象
axios.prototype.baseURL = 'http://10.0.42.94:80/api';


//引入 rem  适配
import './tool/rem'
// 引入nut UI
import NutUI from '@nutui/nutui';
import '@nutui/nutui/dist/nutui.css';
// 引入 vant UI
import Vant from 'vant';
import 'vant/lib/index.css';

// 引入jsonp跨域
import { VueJsonp } from 'vue-jsonp';
Vue.use(VueJsonp);


//引入微信登录插件
import wechatAuth from './tool/wechatAuth'
const qs = require('qs');
Vue.use(wechatAuth, { appid: 123 });

// ======================================================================================================================================
// 国际化
// VueI18n插件使用
import { i18n } from './lang/index'
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n);
// =============================================================================================
// nutui国际化
import nEnUS from '@nutui/nutui/dist/locales/lang/en-US';
export const eventBus = new Vue()
// 挂载到全局
Vue.use(NutUI, {
  locale: 'en-US',
  lang: nEnUS
});
// // // vantui国际化
import { Locale } from 'vant';
Locale.use('zhCN', i18n.messages.zhCN);
Vue.use(Vant);
// ====================================================================================================================================

Vue.config.productionTip = false
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
