const en = require('vant/es/locale/lang/en-US')

module.exports = {
    '景点': 'Attractions',
    '美食': 'Gourmet',
    '住宿': 'Hotel ',
    '文化': 'Culture',
    '特产': 'Specialty',
    '地图': 'Map',
    '非遗': 'ICH',
    '商城': 'Shopping Mall',
    '我': 'Me',
    '路线': 'Route',
    '3D趣赏': '3D fun',
    'AR导览': 'Ar Guide',
    'VR全景': 'VR panorama',
    '去这里': 'Go here',
    '留言': 'Leaving a message',
    '留言墙': 'message wall',
    '请输入留言': 'please enter a message',
    '非遗文化': 'Intangible cultural heritage',
    '趣赏非遗': 'Enjoy intangible cultural heritage',
    '非遗项目': '',
    '曲艺': '',
    '查看': '',
    '全部级别': '',
    '世界级': '',
    '国家级': '',
    '省级': '',
    '市级': '',
    '县级': '',
    '传承人': '',
    '阅': '',
    '云游青海': '',
    '游客模式': '',
    '微信登录': '',
    '分享': 'Share',
    '微信': '',
    '微博': '',
    '朋友圈': '',
    'QQ': '',
    '男': 'man',
    '女': 'woman',
    '保存': 'Save',
    'AR趣玩': 'Ar fun',
    '线上展馆': 'Online pavilion',
    '游戏互动': 'Game interaction',
    '云赏非遗': 'Cloud appreciation of intangible cultural heritage',
    '各派戏曲': 'Various genres of opera',
    '传统音乐': 'Traditional music',
    '传统美术': 'Traditional art',
    '民族舞蹈': 'Folk dance',
    '民间文学': 'Folk Literature',
    '传统医学': 'Traditional medicine',
    '传统技艺': 'Traditional skills',
    '民俗文化': 'Folk culture',
    '各类乐器': 'Various musical instruments',
    '民族服饰': 'National Costume',
    '个人信息': 'Personal Information',
    '我的足迹': 'My footprints',
    '我的收藏': 'My Favorites',
    '昵称：': 'Nickname',
    '请输入昵称': 'Please enter a nickname',
    '性别：': 'Gende',
    '保密：': 'secrecy',
    '电话：': 'Telephone',
    '请输入手机号': 'Please enter your mobile number',
    ...en.default
}