const ja = require('vant/es/locale/lang/ja-JP')

module.exports = {
    '景点': 'スポット',
    '美食': 'グルメ',
    '住宿': '宿泊',
    '文化': '文化',
    '特产': '土産',
    '地图': 'アクセス',
    '非遗': '無形文化遺産',
    '商城': 'ショップ',
    '我': 'マイページ',
    '路线': '交通情報',
    '3D趣赏': '3D楽賞',
    'AR导览': 'ARツアー',
    'VR全景': 'VR観光',
    '去这里': '注目',
    '留言': '掲示板',
    '留言墙': '掲示板',
    '请输入留言': 'どうぞ、コメントを',
    '非遗文化': '無形文化遺産',
    '趣赏非遗': '文化遺産を鑑賞しよう',
    '非遗项目': '',
    '曲艺': '',
    '查看': '',
    '全部级别': '',
    '世界级': '',
    '国家级': '',
    '省级': '',
    '市级': '',
    '县级': '',
    '传承人': '',
    '阅': '',
    '云游青海': '',
    '游客模式': '',
    '微信登录': '',
    '分享': '',
    '微信': '',
    '微博': '',
    '朋友圈': '',
    'QQ': '',
    '男': '男',
    '女': '女',
    '保存': '保存',
    'AR趣玩': 'AR遊び',
    '线上展馆': 'オンライン展示',
    '游戏互动': 'ゲーム体験',
    '云赏非遗': 'オンライン鑑賞',
    '各派戏曲': '伝統戯曲',
    '传统音乐': '伝統音楽',
    '传统美术': '伝統美術',
    '民族舞蹈': '民間ダンス',
    '民间文学': '民間文学',
    '传统医学': '伝統医学',
    '传统技艺': '伝統腕前',
    '民俗文化': '民俗文化',
    '各类乐器': '民俗楽器',
    '民族服饰': '民俗衣装',
    '个人信息': 'プロフィール',
    '我的足迹': '履歴',
    '我的收藏': 'お気に入り',
    '昵称：': 'ニックネーム',
    '请输入昵称': 'ニックネームを入力してください',
    '性别：': '性別',
    '保密：': '秘密',
    '电话：': '電話番号',
    '请输入手机号': '電話番号を入力してください',
    ...ja.default
}