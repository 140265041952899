import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lists: [], //非遗项目
    detailLists: [],//非遗项目详情
    homeData: [], // 景点坐标信息 
    skip: [],// 趣赏非遗类型
    // PathData: []
    position:false
  },
  mutations: {
    setLists(state, data) {
      state.lists = data
    },
    setDetails(state, data) {
      console.log(data);
      state.detailLists = data
    },
    sethomeData(state, data) {
      state.homeData = data
    },
    setPathData(state, data) {
      state.PathData = data
    },
    // 趣赏非遗
    skipType(state, data) {
      state.skip = data
    },
    setPosition(state,type){
      console.log(type);
      state.position = type
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
